// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"src/components/layout/Breadcrumb.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "src/components/layout/Breadcrumb.tsx");
  import.meta.hot.lastModified = "1733087018000";
}
// REMIX HMR END

import React from 'react';
import { useMatches } from '@remix-run/react';
import { Link } from '@remix-run/react';
export const Breadcrumb = () => {
  _s();
  const matches = useMatches();
  // @ts-ignore ok
  if (!matches.slice(1).filter(m => m.handle && m.handle.breadcrumb).length) {
    return null;
  }
  return <nav className="flex border-b border-gray-200 dark:border-slate-500 bg-white dark:bg-slate-800" aria-label="Breadcrumb">
      <ol role="list" className="ml-4 flex w-full max-w-screen-xl space-x-4 px-4 sm:px-6 lg:px-8">
        <li className="flex">
          <div className="flex items-center">
            <Link to={matches[0].pathname} className="my-4 text-gray-400 hover:text-gray-500 dark:text-slate-400 dark:hover:text-gray-300">
              Home
            </Link>
          </div>
        </li>

        {matches.slice(1)
      // @ts-ignore ok
      .filter(match => match.handle && match.handle.breadcrumb).map((match, index) => {
        {
          /* @ts-ignore ok */
        }
        const result = match.handle.breadcrumb(match);
        const isArray = Array.isArray(result);
        const renderIt = (comp, idx = 0) => <li key={`match-${index}-${idx}`} className="flex">
                <div className="flex items-center">
                  <svg className="h-full w-6 flex-shrink-0 text-gray-200 dark:text-slate-500" viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" aria-hidden="true">
                    <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                  </svg>
                  <div
            // to={match.pathname}
            className="ml-4 text-sm font-medium text-gray-500 dark:text-slate-400 hover:text-gray-700 dark:hover:text-gray-300"
            // @ts-ignore ok
            aria-current={match.current ? 'page' : undefined}>
                    {comp}
                  </div>
                </div>
              </li>;
        return isArray ? result.map((r, idx) => renderIt(r, idx)) : renderIt(result);
      })}
      </ol>
    </nav>;
};
_s(Breadcrumb, "9HQn1rkLPttBP+QSK6GDQicXTV4=", false, function () {
  return [useMatches];
});
_c = Breadcrumb;
var _c;
$RefreshReg$(_c, "Breadcrumb");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;